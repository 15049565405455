import styled from 'styled-components';
import React from 'react';
import { plusBtn, minusBtn, mintBtn } from './../dapp-scripts/mint';
import HeroLineImage from '.././assets/hero-line.png';
import NFTCover from '.././assets/NFTImage.png';
import {DescriptionField, BannerTextField, TitleTextOne, TitleTextTwo, TitleTextThree, SalesModeText, SalesModeTextLive, PriceText, SupplyText} from './../dapp-scripts/text-fieds';

export function App() {
  return (
    <Hero>
      <HeroLeft>          
        <TopBannerText>{BannerTextField}</TopBannerText>
        <TitleHeroLine src={HeroLineImage}/>
        <TitleHeroText>{TitleTextOne} <br /> {TitleTextTwo} <span className="TitleColor">{TitleTextThree}</span></TitleHeroText>
        <Description>{DescriptionField}</Description>
        <SalesModes>{SalesModeText} <span id="ActualMode">{SalesModeTextLive}</span></SalesModes>        
        <MintdApp>
          <MinusBtn onClick={minusBtn}>-</MinusBtn>
          <MintBtn onClick={mintBtn}>MINT<span id="inpValue"></span></MintBtn>
          <PlusBtn onClick={plusBtn}>+</PlusBtn>
        </MintdApp>
        <BottomDApp>
          <PriceMint>{PriceText} <span id="val"></span></PriceMint>
          <TotalSupply>{SupplyText} <span id="MintedAmount">0</span>/<span id="MaxToken">10000</span></TotalSupply>
        </BottomDApp>
      </HeroLeft>
      <MiddleLine />
      <HeroRight>
        <ContainerImages>
          <NFTImage src={NFTCover} />
        </ContainerImages>
      </HeroRight>
    </Hero>
  );
}

export default App;

const Hero = styled.div`
height: 779px;
display: grid;
grid-template-columns: repeat(5,1fr);

@media only screen and (max-width: 1080px) {
height: 1040px;
grid-template-rows: repeat(2,1fr);
margin-top: 30px;
margin-bottom: 50px;    
}

@media only screen and (max-width: 500px) {
height: 880px;     
grid-template-columns: repeat(3,1fr);
}

`;

const HeroLeft = styled.div`
grid-column: 2;
align-self: center;
width: 500px;

@media only screen and (max-width: 1080px) {
grid-column: 3;
width: auto;
grid-row: 2;
}

@media only screen and (max-width: 500px) {
grid-column: 2;
width: auto;
grid-row: 2;
}
`;

const HeroRight = styled.div`
grid-column: 4;
align-self: center;

@media only screen and (max-width: 1080px) {
grid-column: 3;
grid-row: 3;
}

@media only screen and (max-width: 500px) {
grid-column: 2;
grid-row: 3;
}
`;

const ContainerImages = styled.div`
@media only screen and (max-width: 512px) {
width: 100%;
height: auto;
text-align-last: center;
}
`;

const TitleHeroLine = styled.img`
position: relative;
height: 12px;
left: 224px;
object-fit: cover;
position: relative;
width: 250px;
top: 73px;

@media only screen and (max-width: 500px) {

left: 136px;
width: 160px;
top: 52px;
border-radius: 20px;
}
`;

const TitleHeroText = styled.h1`
color: white;
font-family: var(--font-family-poppins);
font-size: var(--font-size-xxxxl);
font-weight: 600;
font-style: normal;
position: relative;
width: 500px;
left: 0;
letter-spacing: 0;
line-height: 75px;

@media only screen and (max-width: 500px) {
width: auto;
font-size: var(--font-size-xxl);
line-height: 50px;
}

`;

const TopBannerText = styled.div`
color: var(--white);
font-family: var(--font-family-poppins);
font-size: var(--font-size-m2);
font-weight: 500;
font-style: italic; 
width: fit-content;
line-height: 30px;
background-color: black;
padding: 0px 15px;

@media only screen and (max-width: 500px) {
width: 100%;
text-align: center;
padding: 0;
}
`;

const Description = styled.div`
color: var(--white);
font-family: var(--font-family-poppins);
font-size: var(--font-size-m);
font-weight: 400;
opacity: 0.8;
margin-bottom: 30px;
margin-top: 16px;
`;

const SalesModes = styled.div`
color: var(--purple-heart);
font-family: var(--font-family-poppins);
font-size: var(--font-size-m);
font-weight: 500;
position: relative;
width: 500px;
text-align: center;
line-height: 50px;
background-color: var(--black);
height: 52px;

@media only screen and (max-width: 512px) {
width: auto;
}
`;

const MintdApp = styled.div`
display: grid; 
grid-template-columns: repeat(5,1fr); 
background:#4E2ACE;
align-items: center;
text-align-last: center;
cursor:pointer;
`;

const MinusBtn = styled.div`
color: var(--white);
font-family: var(--font-family-poppins);
font-size: 35px;
font-weight: 600;
font-style: normal;  
grid-column: 1;
background:#4123AC;
width: 83px;
text-align-last: center;
@media only screen and (max-width: 512px) {
min-width: 50px;
height: auto;
}
`;

const MintBtn = styled.div`
color: var(--white);
font-family: var(--font-family-poppins);
font-size: var(--font-size-l);
font-weight: 600;
font-style: normal;
grid-column: 3;

@media only screen and (max-width: 512px) {
white-space: nowrap;
}
`;

const PlusBtn = styled.div`
color: var(--white);
font-family: var(--font-family-poppins);
font-size: 35px;
font-weight: 600;
font-style: normal;  
grid-column: 5;
background:#4123AC;
width: 83px;
justify-self: self-end;
text-align-last: center;
@media only screen and (max-width: 512px) {
min-width: 50px;
height: auto;
}
`;

const BottomDApp = styled.div`
height: 28px;
margin-top: 5px;
margin-right: 1.23px;
display: flex;
justify-content: space-between;
font-family: var(--font-family-poppins);
font-size: var(--font-size-m);
font-weight: 500;
font-style: italic;  

@media only screen and (max-width: 1280px) {
margin-bottom:40px;
font-size: var(--font-size-s);
}
`;

const PriceMint = styled.div`
color: var(--white);
width: 141px;
letter-spacing: 0;
line-height: 30px;
white-space: nowrap;
`;

const TotalSupply = styled.div`
color: var(--purple-heart);
width: 193px;
text-align: right;
letter-spacing: 0;
line-height: 30px;
white-space: nowrap;
`;

const MiddleLine = styled.div`
left: calc(50.2%);
height: 450px;
position: absolute;
align-self: center;
border: 2px solid #141414;

@media only screen and (max-width: 1280px) {
display:none;
}
`;

const NFTImage = styled.img`
width: 500px;
@media only screen and (max-width: 500px) {
width: 330px;
}
`;
