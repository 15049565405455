//POPUP FIELDS
export const PleaseConnectWallet = "Please Connect your Wallet";
export const SuccessConnectWallet = "Wallet Connected";
export const WrongChainID = "Please Switch Network to Goerli Testnet"
export const SomethingWrong = "Retry"
export const MintingTitle = "Minting Started";
export const MintingDescription = "Please Wait...";
export const SuccessMintingTitle = "NFT Minted!";
export const SuccessMintingDescription = "Look what you got in OpenSea!";

//HEADER
export const ConnectText = "Connect Wallet";
export const LogoTextOne = "TURKEY";
export const LogoTextTwo = "EGGS";

//HERO
export const BannerTextField = "Join the space and build the new world";
export const TitleTextOne = "Get a TURKEY";
export const TitleTextTwo = "Join the";
export const TitleTextThree = "HUB";
export const DescriptionField = "Welcome to the first drop of the NFT Turkey Eggs collection. By minting an egg, you get a minting slot for the the exclusive egg awakening phase.";
export const SalesModeText = "Sales Mode:";
export const SalesModeTextLive = "Please Connect";
export const PriceText = "Price:";
export const SupplyText = "Supply:";

//HEROLINE
export const BlockchainTopText = "Ethereum";
export const BlockchainBottomText = "Blockchain";
export const MintDateTopText = "11.11.2022";
export const MintDateBottomText = "Mint Date";
export const NFTPriceTopText = "0.05 ETH";
export const NFTPriceBottomText = "NFT Price";