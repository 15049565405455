import styled from 'styled-components';
import React from 'react';
import {BlockchainTopText, BlockchainBottomText, MintDateTopText, MintDateBottomText, NFTPriceTopText, NFTPriceBottomText} from "../dapp-scripts/text-fieds";

function App() {
  return (
    <HeroLine >
        <BlockchainContainer >
          <TopText >{BlockchainTopText}</TopText>
          <BottomText >{BlockchainBottomText}</BottomText>
        </BlockchainContainer>
        <ContainerMintDate >
          <TopText >{MintDateTopText}</TopText>
          <BottomText >{MintDateBottomText}</BottomText>
        </ContainerMintDate>
        <ContainerNFTprice >
          <TopText >{NFTPriceTopText}</TopText>
          <BottomText >{NFTPriceBottomText}</BottomText>
        </ContainerNFTprice>
    </HeroLine>
  );
}

export default App;

const HeroLine = styled.div`
display: grid; 
grid-template-columns: repeat(3,1fr);
background-color:black;
height: 100px;
`;

const BlockchainContainer = styled.div`
grid-column: 1;
text-align: center;
border-left:solid 5px #4E2ACE;
`;

const TopText = styled.div`
color: var(--white);
font-family: var(--font-family-poppins);
font-size: var(--font-size-m);
font-weight: 500;
font-style: italic;
margin-top: 20px;

`;

const BottomText = styled.div`
color: var(--purple-heart);
font-family: var(--font-family-poppins);
font-size: var(--font-size-m);
font-weight: 500;
font-style: italic;
margin-bottom: 20px;
`;

const ContainerMintDate = styled.div`
grid-column: 2;
text-align: center;
border-left:solid 5px #4E2ACE;
border-right:solid 5px #4E2ACE;

`;

const ContainerNFTprice = styled.div`
grid-column: 3;
text-align: center;
border-right:solid 5px #4E2ACE;
`;