import '../App.css';
import Web3Modal from "web3modal";
import { ethers } from 'ethers';
import Swal from 'sweetalert2';
import Web3 from "web3";
import CONTRACT_ABI from "../contract/abi.json";
import { CONTRACT_ADDRESS, mintPrice, MaxNFTperWallet, chainID } from '../dapp-scripts/mint_info';
import { PleaseConnectWallet, MintingTitle, MintingDescription, SuccessConnectWallet, WrongChainID, 
SomethingWrong, SuccessMintingTitle, SuccessMintingDescription } from './text-fieds';

var chainId = chainID;
var web3;
var isConnected = false;
var load_contract;
var MintedAmount;
var MaxToken;
let web3Modal;
let provider;
let WalletLoaded;

const providerOptions = {
   
}; 
web3Modal = new Web3Modal({
cacheProvider: true,
providerOptions,
disableInjectedProvider: false,
});

async function updateTotalValue() {
    
        let mul = parseInt(document.getElementById("inpValue").textContent );
        var totalPrice = (mul * mintPrice).toFixed(2);
        document.getElementById("val").innerText = totalPrice + " ETH";
    
}

export async function plusBtn() {
    let qty = parseInt(document.getElementById("inpValue").textContent );
    qty = isNaN(qty) ? 0 : qty;
    if (qty < MaxNFTperWallet) {
        qty++;
        document.getElementById("inpValue").textContent  = qty;
        await updateTotalValue();
    };
}

export async function minusBtn() {
    let value = parseInt(document.getElementById("inpValue").textContent );
    value = isNaN(value) ? 0 : value;
    if (value > 1) {
        --value;
        document.getElementById("inpValue").textContent = value;
        await updateTotalValue();
    };
}

async function fetchAccountData() {
    web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    chainId = await web3.eth.getChainId();
    console.log("Connected using wallet " + accounts);
    if (chainId === chainID) {
        load_contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
        isConnected = true;
        await load_contract.methods.totalSupply().call().then((res) => { MintedAmount = res; document.getElementById("MintedAmount").innerHTML = MintedAmount; });
        await load_contract.methods.MaxToken().call().then((res) => { MaxToken = res; document.getElementById("MaxToken").innerHTML = MaxToken; });
        WalletLoaded = accounts[0];
        Swal.fire({ title: SuccessConnectWallet, icon: "success" });
        let start = accounts[0].slice(0, 7);
        let end = accounts[0].slice(37, 42);
        document.getElementById("WalletConnectBtn").innerHTML = start + "..." + end;
    } else {
        Swal.fire({
            title: WrongChainID,
            icon: "error"
        });
    }
}
async function refreshAccountData() {
    await fetchAccountData(provider);
}

export async function onConnect() {
    try { provider = await web3Modal.connect(); } catch (e) { console.log("Cant connect wallet", e); return; }
    provider.on("accountsChanged", (accounts) => { fetchAccountData(); });
    provider.on("chainChanged", (chainId) => { fetchAccountData(); });
    provider.on("networkChanged", (networkId) => { fetchAccountData(); });
    ActualMode = "Public Sale";
    document.getElementById("ActualMode").innerText = ActualMode;
    await refreshAccountData();
}

var isBalanceAmount;
var ActualMode;
var mintPriceValue
export const mintBtn = (async function(e) {
    e.preventDefault();
    let qty = parseInt(document.getElementById("inpValue").textContent );
    let mintValue = ethers.utils.parseEther(qty.toString()) * mintPriceValue;
    const TotalMintPrice = mintValue.toString();
    console.log("Total Mint Price: " + TotalMintPrice);
    if (isConnected) {
        console.log("Mint Function Called");
        await load_contract.methods.balanceOf(WalletLoaded.toString()).call().then((res) => { isBalanceAmount = (res).toString(); console.log("Current NFT Balance: " + isBalanceAmount); });
        const num1 = parseInt(qty);
        const num2 = parseInt(isBalanceAmount);
        const BalanceCalculMax = num1 + num2;
        console.log("Trying to mint " + num1 + " | Balance after mint: " + BalanceCalculMax);
        mintPriceValue = mintPrice;
        let mintValue = ethers.utils.parseEther(qty.toString()) * mintPriceValue;

                if (BalanceCalculMax > MaxNFTperWallet) { Swal.fire({ icon: 'error', title: 'Cant mint more than ' + MaxNFTperWallet }) } 
                else web3.eth.getBalance(WalletLoaded, function(err, result) 
                {
                    if (result < mintValue) { Swal.fire({ icon: 'error', title: 'Not enough balance' }) } 
                    else { Swal.fire({ title: MintingTitle, html: MintingDescription, timerProgressBar: true, allowOutsideClick: false,
                            didOpen: () => {
                                Swal.showLoading()
                                Swal.getHtmlContainer().querySelector('b')
                            }
                        })
                        load_contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
                        load_contract.methods.mint(qty).send({ from: WalletLoaded, value: mintValue })
                        .then((res) => { Swal.fire(SuccessMintingTitle, SuccessMintingDescription, 'success')})
                        .catch(() => { Swal.fire({ title: SomethingWrong, icon: "error" }) })
                    }
                })
            
       
    } else { Swal.fire({ title: PleaseConnectWallet, icon: "error",  }); }
});