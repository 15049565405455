import styled from 'styled-components';
import React from 'react';
import { onConnect } from './../dapp-scripts/mint';
import {ConnectText, LogoTextOne, LogoTextTwo} from '../dapp-scripts/text-fieds';

function HeaderApp() {
  return (
    <Header >
        <Logo>{LogoTextOne} <span className="TitleColor">{LogoTextTwo}</span></Logo>
        <ConnectWalletTextBtn onClick={onConnect} id="WalletConnectBtn">{ConnectText}</ConnectWalletTextBtn>
    </Header>
  );
}

export default HeaderApp;

const Logo = styled.div`
color: white;
font-family: var(--font-family-poppins);
font-size: var(--font-size-l);
font-weight: 700;
font-style: normal;
grid-column: 3;
align-self: center;
width:200px;
cursor:pointer;
@media only screen and (max-width: 500px) {
font-size: var(--font-size-m);
width:125px;
grid-column: 2;
}
`;

const Header = styled.div`
background-color: var(--woodsmoke);
background-color: var(--woodsmoke);
display: grid;
grid-template-columns: repeat(13,1fr);
height:90px;

@media only screen and (max-width: 500px) {
grid-template-columns: repeat(5,1fr);
}
`;

const ConnectWalletTextBtn = styled.div`
color: var(--white);
font-family: var(--font-family-poppins);
font-size: var(--font-size-m);
font-weight: 600;
font-style: normal;
padding: 10px;
background-color: var(--purple-heart-2);
align-self: center;
grid-column: 11;
width:fit-content;
white-space: nowrap;
cursor:pointer;
@media only screen and (max-width: 500px) {
padding: 7px;
grid-column: 4;
}
`;